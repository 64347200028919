var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-form',{ref:"confirmarRecuperarSenhaForm",on:{"submit":function($event){$event.preventDefault();return _vm.confirmarRecuperarSenha.apply(null, arguments)}}},[_c('v-card',{attrs:{"rounded":"","width":"1000"}},[_c('v-card-title',{staticClass:"justify-center titulo"},[_vm._v("Confirmação de Recuperação de Senha ")]),_c('v-card-subtitle',{staticClass:"text-center"},[_vm._v(" Para validar a sua nova senha, insira o seu CPF ")]),_c('v-card-text',[_c('v-divider',{staticClass:"mb-5"}),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('###########'),expression:"'###########'"}],attrs:{"outlined":"","dense":"","maxlength":"11","counter":11,"rules":[_vm.campoObrigatorioRule, _vm.tamanhoCPFRule],"label":"CPF *","validate-on-blur":""},model:{value:(_vm.cpf),callback:function ($$v) {_vm.cpf=$$v},expression:"cpf"}}),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"outlined":"","dense":"","rules":[_vm.campoObrigatorioRule, _vm.formatoDataRule],"label":"Data de Nascimento *","validate-on-blur":""},model:{value:(_vm.dataNascimento),callback:function ($$v) {_vm.dataNascimento=$$v},expression:"dataNascimento"}}),_c('v-text-field',{attrs:{"outlined":"","dense":"","type":"password","minlength":"8","hint":"Mínimo de 8 caracteres","rules":[_vm.campoObrigatorioRule, _vm.tamanhoSenhaRule],"label":"Nova Senha *","validate-on-blur":""},model:{value:(_vm.novaSenha),callback:function ($$v) {_vm.novaSenha=$$v},expression:"novaSenha"}}),_c('v-text-field',{attrs:{"outlined":"","dense":"","type":"password","minlength":"8","rules":[
                _vm.campoObrigatorioRule,
                _vm.camposIguaisRule(
                  _vm.confirmacaoNovaSenha,
                  _vm.novaSenha,
                  'Senha diferente da informada!'
                )
              ],"label":"Confirmação Nova Senha *","validate-on-blur":""},model:{value:(_vm.confirmacaoNovaSenha),callback:function ($$v) {_vm.confirmacaoNovaSenha=$$v},expression:"confirmacaoNovaSenha"}})],1),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{staticClass:"mt-4 text-center",attrs:{"loading":_vm.loading,"color":"primary","type":"submit"}},[_vm._v("Confirmar ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }