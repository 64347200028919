<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="auto">
        <v-form
            ref="confirmarRecuperarSenhaForm"
            @submit.prevent="confirmarRecuperarSenha"
        >
          <v-card rounded width="1000">
            <v-card-title class="justify-center titulo"
            >Confirmação de Recuperação de Senha
            </v-card-title>
            <v-card-subtitle class="text-center">
              Para validar a sua nova senha, insira o seu CPF
            </v-card-subtitle>
            <v-card-text>
              <v-divider class="mb-5"/>
              <v-text-field
                  outlined
                  dense
                  v-model="cpf"
                  maxlength="11"
                  :counter="11"
                  :rules="[campoObrigatorioRule, tamanhoCPFRule]"
                  label="CPF *"
                  v-mask="'###########'"
                  validate-on-blur
              ></v-text-field>
              <v-text-field
                  outlined
                  dense
                  v-model="dataNascimento"
                  :rules="[campoObrigatorioRule, formatoDataRule]"
                  label="Data de Nascimento *"
                  v-mask="'##/##/####'"
                  validate-on-blur
              ></v-text-field>
              <v-text-field
                  outlined
                  dense
                  type="password"
                  v-model="novaSenha"
                  minlength="8"
                  hint="Mínimo de 8 caracteres"
                  :rules="[campoObrigatorioRule, tamanhoSenhaRule]"
                  label="Nova Senha *"
                  validate-on-blur
              ></v-text-field>
              <v-text-field
                  outlined
                  dense
                  type="password"
                  v-model="confirmacaoNovaSenha"
                  minlength="8"
                  :rules="[
                  campoObrigatorioRule,
                  camposIguaisRule(
                    confirmacaoNovaSenha,
                    novaSenha,
                    'Senha diferente da informada!'
                  )
                ]"
                  label="Confirmação Nova Senha *"
                  validate-on-blur
              ></v-text-field>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn
                  :loading="loading"
                  class="mt-4 text-center"
                  color="primary"
                  type="submit"
              >Confirmar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import recuperarSenhaService from "@/services/recuperarSenha.service";
import rules from "@/commons/rules";
import {mapActions} from "vuex";
import luxon from "@/core/luxon";

export default {
  name: "RecuperarSenha",

  data() {
    return {
      token: this.$route.query.token,
      cpf: "",
      dataNascimento: "",
      novaSenha: "",
      confirmacaoNovaSenha: "",
      loading: false
    };
  },

  methods: {
    ...rules,
    ...mapActions("avisos", ["exibirAviso"]),

    confirmarRecuperarSenha() {
      if (this.$refs.confirmarRecuperarSenhaForm.validate() && this.token) {
        this.loading = true;

        let dados = {
          token: this.token,
          novaSenha: this.novaSenha,
          cpf: this.cpf,
          dataNascimento: luxon.localDateToDateTime(this.dataNascimento.replace(/\//g, '-')).toJSDate().getTime()
        };

        console.log(dados.dataNascimento)

        recuperarSenhaService
            .confirmarRecuperarSenha(dados)
            .then(() => {
              this.exibirAviso({
                mensagem:
                    "Senha recuperada com sucesso. Você já pode efetuar o seu login",
                tipo: "success"
              });

              this.$router.push("/");
            })
            .catch(error => {

              let errorData = error.response.data;

              if(errorData.codigoErro === "JW02") {
                error.response.data.msg = "Prazo para recuperar senha expirado, favor pedir nova recuperação"
              }

              this.exibirAviso({
                mensagem: error.response.data.msg,
                tipo: "error"
              });
            });
      } else if (!this.token) {
        this.$router.push("/naoAutorizado");
      }

      this.loading = false;
    }
  }
};
</script>
